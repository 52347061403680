import docReady from 'doc-ready'
import Flickity from 'flickity'
import 'flickity/css/flickity.css'

import {addHandler, removeHandler} from '../util/scroll_resize'
import {initContainers, teardownContainers} from '../util/init'
import {updateLinkState} from '../util/link_state'
import ScrollBalance from '../util/scrollbalance'
import {toggle, cutPaste, windowSize, parallax, lazyLoad, scrollPointTrigger} from '../util/tools'
import {PHONE_MAX, TABLET_PORTRAIT_MAX} from './constants'
import AjaxLoader, {isLocalLink} from '../util/ajax_util'

let init
export let headerHeight
export let galleryExpanded = false
export let pageClasses
export let ajaxPageLoad
const transitionendPrefixed = ['transitionend', 'webkitTransitionEnd']

docReady(() => {
  pageClasses = [...document.body.classList]
  let setup = new SiteSetup()
  init = new SiteInit()
  window.setTimeout(() => {
    // first up reveal (js only)
    document.body.style.opacity = null
    document.documentElement.classList.add('state-initialised')
  }, 100)
})

class SiteSetup {
  constructor () {
    this.windowWidth = null
    this.header = document.querySelector('#header .inside')

    // bind navigation burger
    const burger = document.querySelector('#header .burger')
    if (burger) {
      burger.addEventListener('click', (e) => {
        e.preventDefault()
        toggle(document.body, 'navigation-visible')
      })
    }

    // shift elements on phone into a new position (header/footer)
    document.querySelectorAll('#header [data-cut-paste], #footer [data-cut-paste]').forEach((el) => {
      cutPaste(el)
    })

    // load all pages with ajax
    // DISABLED (not yet complete) this.initAjaxPages()

    addHandler({
      resize: (w, h) => {
        this.windowWidth = w
        headerHeight = this.header.offsetHeight
      },
      scroll: (top, left) => {}
    }, true)
  }
  /**
  * all site transitions from page to page using ajax
  * TODO edit speeds pause (possibly run it as a single swipe?)
  *      scroll positions/ above or below nav height.
  *
  */
  initAjaxPages () {
    const content = document.querySelector('#content')
    // init ajax loader
    ajaxPageLoad = new AjaxLoader('chamberson', (newBody, state) => {
      const previousArticle = content.querySelector('article')
      let processingSwitchPage = false
      let runRemoveStaleTransitionClasses = false
      const removeStaleTransitionClasses = (e) => {
        runRemoveStaleTransitionClasses = true
        // ie 11 doesn't remove multiple classes
        let staleClasses = ['prevent-multiple-ajax']
        for (let c in staleClasses) {
          document.body.classList.remove(staleClasses[c])
        }
        // strip out the (transition out) event listener
        // transitionendPrefixed.forEach(transitionend =>
        //   curtain.removeEventListener(transitionend, removeStaleTransitionClasses, true)
        // )
      }
      const switchPage = (e) => {
        processingSwitchPage = true
        // strip out the custom page classes
        pageClasses.forEach((oldClass) => {
          document.body.classList.remove(oldClass)
        })
        // drop in the new classes
        const newPageClasses = [...newBody.classList]
        newPageClasses.forEach((newPageClass) => {
          document.body.classList.add(newPageClass)
        })
        // reassign for the next unload
        pageClasses = newPageClasses
        // drop in the new content
        const newArticle = newBody.querySelector('#content > article')
        content.appendChild(newArticle)
        // bind new ajax links
        this.bindAjaxLinks(newArticle)
        // TODO remove previousArticle
        if (!state) {
          // reset the scroll position - unless we're going back
          const scrollReset = this.windowWidth > PHONE_MAX ? 102 : 0
          document.documentElement.scrollTop = scrollReset
          document.body.scrollTop = scrollReset
        }
        // run a new init, this handles all general + page specific js
        init = new SiteInit(newArticle)
        // add a delay - for dramatic effect
        // window.setTimeout(() => {
        //   // if we passed a (curtain-in) transition in
        //   if (document.body.classList.contains('curtain-in')) {
        //     // strip out the (transition in) event listener
        //     transitionendPrefixed.forEach(transitionend =>
        //       curtain.removeEventListener(transitionend, switchPage, true)
        //     )
        //     // apply transition class (out)
        //     document.body.classList.add('curtain-out')
        //     // once the transition (out) has ended remove remnant classes
        //     transitionendPrefixed.forEach((transitionend) => {
        //       curtain.addEventListener(transitionend, removeStaleTransitionClasses, true)
        removeStaleTransitionClasses()
        //     })
        //     // fallback
        //     window.setTimeout(() => {
        //       if (!runRemoveStaleTransitionClasses) {
        //         removeStaleTransitionClasses()
        //       }
        //     }, 1500)
        //   } else {
        //     // assime that we're on phone with the navigation-visible
        //     document.body.classList.remove('navigation-visible')
        //     this.everything.style.top = null
        //     removeStaleTransitionClasses()
        //   }
        // }, 800)
      }
      // RUN THE PAGE SWITCH
      // apply transition class (in)
      if (!document.body.classList.contains('navigation-visible')) {
        // document.body.classList.add('curtain-in')
        // once the transition (in) has ended swith/load the new page
        // transitionendPrefixed.forEach((transitionend) => {
        //   curtain.addEventListener(transitionend, switchPage, true)
        // })
        // fallback
        // window.setTimeout(() => {
        if (!processingSwitchPage) {
          switchPage()
        }
        // }, 1500)
      } else {
        // no curtain transition
        // skip this transition for navigation-visible (phone)
        switchPage()
      }
    }, {
      onBeforeLoad: (url) => {
        const previousArticle = content.querySelector('article')
        // 1. strip out the resize/scroll listeners for the current page (specified on each page js)
        teardownContainers(previousArticle)
        // 2. likewise strip the handler off the siteinit functions (below)
        init.teardown.forEach((handler) => {
          removeHandler(handler)
          if (handler.teardown) handler.teardown()
        })
      }
    })

    // first call bind entire DOM
    this.bindAjaxLinks(document.body)
  }
  /**
  * all site transitions from page to page using ajax
  * TODO consider scroll links within page
  * @function bindAjaxLinks
  * @param {HtmlElement} selector
  *
  */
  bindAjaxLinks (container) {
    container.querySelectorAll('a[href]').forEach((a) => {
      const url = a.getAttribute('href')
      if (url && isLocalLink(a)) {
        a.addEventListener('click', (e) => {
          const modifier = e.shiftKey || e.ctrlKey || e.metaKey
          if (!modifier && !a.classList.contains('current')) {
            e.preventDefault()
            if (!document.body.classList.contains('prevent-multiple-ajax')) {
              document.body.classList.add('prevent-multiple-ajax')
              ajaxPageLoad.load(url, null)
            }
          }
        })
      }
    })
  }
}

class SiteInit {
  // initialises newly loaded content
  // recallable for ajax loaded content
  constructor (currentArticle) {
    // establish a new teardown for ajax page unload
    this.windowWidth = windowSize().width
    this.teardown = []

    const currentArticles = document.querySelectorAll('#content > article')
    currentArticle = !currentArticle ? currentArticles[0] : currentArticle

    currentArticle.querySelectorAll('.gallery').forEach((gallery) => {
      this.initGalleries(gallery)
    })

    this.initScrollbalance(currentArticle.querySelectorAll('[data-request-scrollbalance]'))
    updateLinkState(document.querySelectorAll('#header, #footer, #content'))

    // setup parallax, items are established with a data-parallax='0.2'
    document.querySelectorAll('[data-parallax]').forEach((p) => {
      const pInstance = parallax(p, {
        factor: p.getAttribute('data-parallax'),
        mobileFactor: p.getAttribute('data-mobile-parallax')
      })
      // add to teardown (for ajax page unload)
      this.teardown.push(pInstance)
    })
    // lazyload images (but not those within galleries (e.g. image 2+))
    const lazySelector = this.windowWidth > TABLET_PORTRAIT_MAX ? '.lazyload:not(.delayed)' : '.lazyload'
    currentArticle.querySelectorAll(lazySelector).forEach((el) => {
      lazyLoad(el)
    })
    // home only TODO move if we create a home.js
    if (currentArticle.querySelector('.tip-top .feature')) {
      scrollPointTrigger(currentArticle.querySelector('.tip-top .feature'), {
        className: 'display-toolbar',
        allowMobile: true,
        applyClass: document.body
      })
    }

    initContainers(currentArticles)

    const handler = addHandler({
      resize: (w, h) => {
        this.windowWidth = w
      },
      scroll: (top, left) => {}
    }, true)

    // add to teardown (for ajax page unload)
    this.teardown.push(handler)
  }
  /**
  * expands the gallery into full screen (tablet-landscape-plus)
  * @function expandGallery
  * @param {HtmlElement} selector
  *
  */

  expandGallery (parent) {
    document.body.classList.add('state-expanded-gallery-present')
    document.body.classList.add('state-unclip-gallery')
    parent.classList.add('state-gallery-active')
    // load all remaining images
    parent.querySelectorAll('.lazyload.delayed').forEach((el) => {
      lazyLoad(el)
    })

    galleryExpanded = true
  }
  /**
  * exported below (for other interactions)
  * collapses the gallery back to standard layouts (tablet-landscape-plus)
  * @function collapseGallery
  * @param {HtmlElement} selector
  *
  */
  collapseGallery (parent) {
    const enableTimeout = 1800 // must be more than the css transition
    // reverse animation order
    const isActive = document.body.classList.contains('state-expanded-gallery-present')
    if (isActive) {
      parent.classList.remove('state-gallery-active')
      document.body.classList.remove('state-expanded-gallery-present')
      window.setTimeout(() => {
        document.body.classList.remove('state-unclip-gallery')
      }, enableTimeout)
    }
    galleryExpanded = false
  }
  /**
  * set up flickity galleries - with a poster to activate it and some controls
  * triggers for expandGallery/collapseGallery etc
  * @function initGalleries
  * @param {HtmlElement} selector
  *
  */
  initGalleries (gallery) {
    let f = null
    const poster = gallery.querySelector('.poster')
    const parent = gallery.parentElement
    const items = gallery.querySelector('.gallery-items')
    const nav = gallery.querySelector('nav')
    const previous = nav.querySelector('.previous')
    const next = nav.querySelector('.next')
    const expand = nav.querySelector('.expand')
    const close = nav.querySelector('.close')
    const isExpandable = gallery.classList.contains('is-expandable') && this.windowWidth > TABLET_PORTRAIT_MAX
    const counterCurrent = nav.querySelector('.counter .current')
    // let currentlyInMotion = false

    const options = {
      freeScrollFriction: 0.075,
      dragThreshold: 80,
      selectedAttraction: 0.025,
      friction: 0.28,
      cellAlign: 'center',
      // contain: true,
      pageDots: false,
      prevNextButtons: false,
      wrapAround: true,
      on: {
        ready: function () {
          //
        },
        settle: (index) => {
          if (counterCurrent) {
            counterCurrent.innerHTML = f.selectedCell.element.getAttribute('data-index')
          }
        },
        staticClick: (e, pointer, cellElement, cellIndex) => {
          f.next(true)
        }
      }
    }

    f = new Flickity(items, options)
    if (next) {
      next.addEventListener('click', (e) => {
        e.preventDefault()
        if (!galleryExpanded && isExpandable) {
          this.expandGallery(parent)
        } else {
          f.next(true)
        }
      })
    }
    if (previous) {
      previous.addEventListener('click', (e) => {
        e.preventDefault()
        if (!galleryExpanded && isExpandable) {
          this.expandGallery(parent)
        } else {
          f.previous(true)
        }
      })
    }
    if (isExpandable) {
      poster.addEventListener('click', (e) => {
        e.preventDefault()
        // if (!currentlyInMotion) {
        // currentlyInMotion = true
        if (!galleryExpanded) {
          this.expandGallery(parent)
        } else {
          this.collapseGallery(parent)
        }
        // }
      })
      expand.addEventListener('click', (e) => {
        e.preventDefault()
        // if (!currentlyInMotion) {
        // currentlyInMotion = true
        this.expandGallery(parent)
        // }
      })
      close.addEventListener('click', (e) => {
        e.preventDefault()
        // if (!currentlyInMotion) {
        // currentlyInMotion = true
        this.collapseGallery(parent)
        // }
      })
    }

    return f
  }
  /**
  * init all requested scrollbalance instances
  * @function initScrollbalance
  * @param {HtmlElement} selector
  *
  */
  initScrollbalance (elements) {
    // let scrollbalanceInstances = []
    elements.forEach((element) => {
      const sb = new ScrollBalance(element, element.firstElementChild, {
        minwidth: TABLET_PORTRAIT_MAX
      })
      // sb.bind()

      const handler = addHandler({
        teardown: () => {
          sb.unbind()
        }
      })
      // scrollbalanceInstances.push(sb)
      // add to teardown (for ajax page unload)
      this.teardown.push(handler)
    })
  }
}

export const collapseGallery = SiteInit.prototype.collapseGallery
