import {getOffset, windowSize, windowScroll} from './tools'

var INNER_CLASSNAME = 'scrollbalance-inner'

export default class ScrollBalance {
  constructor (column, inner, options) {
    this.column = column
    this.inner = inner
    this.columnData = {
      // default initial values here
      fixTop: 0
    }
    this.columnStyles = null
    this.settings = Object.assign({
      // threshold for activating the plugin, eg the column heights must
      // differ by at least this amount to be affected.
      threshold: 100,
      // disable the plugin if the screen width is less than this
      minwidth: null
    }, options || {})
    this.balance_enabled = true
    this.scrollTop = 0
    this.scrollDelta = 0

    this.setup()
    this.bind()
    window.setTimeout(() => {
      this.initialize()
    }, 200)
  }
  setup () {
    /* Append an "inner" element to each column, if it isn't already there,
       and move the column's content into this element, so that the
       content's vertical position can be controlled independently of the
       column's (usually floated) position.
       Should only be called once, on setup. */
    const tempParentStyles = window.getComputedStyle(this.column.parentElement)
    if (tempParentStyles.position === 'static') {
      this.column.parentElement.style.position = 'relative'
    }

    if (!this.inner) {
      this.inner = document.createElement('div')
      this.inner.className = INNER_CLASSNAME
      // inner.innerHTML = column.innerHTML
      const children = [...(this.column.children)]
      children.forEach((child) => { this.inner.appendChild(child) })
      this.inner.setAttribute('data-sb-created', true)
      this.column.innerHTML = ''
      this.column.appendChild(this.inner)
    }
  }
  initialize () {
    /* Position column inner absolutely within the column,
       and set the column height, since their content is now
       positioned absolutely.
       Should be called whenever column content changes, or window
       is resized. */
    const getColumnHeight = (col) => {
      // TODO border included?
      const tempInnerStyles = window.getComputedStyle(this.inner)
      const margin = parseFloat(tempInnerStyles.marginTop) +
                   parseFloat(tempInnerStyles.marginBottom)
      return Math.ceil(this.inner.offsetHeight + margin)
    }
    // Calculate the maximum column height, i.e. how high the container
    // should be (don't assume the user is using a clearfix hack on their
    // container), and the container offset. If there's only one column, use
    // the parent for both calculations
    this.containerHeight = this.column.parentElement.offsetHeight
    this.containerTop = getOffset(this.column.parentElement).top

    this.columnStyles = window.getComputedStyle(this.column)

    // var columnData = this.columnData

    // calculate actual height regardless of what it's previously been
    this.columnData.height = getColumnHeight(this.column)

    // disable if not enough difference in height between container and column
    this.columnData.enabled = (this.containerHeight - this.columnData.height) >
      this.settings.threshold

    this.columnData.fixLeft = getOffset(this.column).left

    this.columnData.minFixTop = Math.min(0, this.winHeight - this.columnData.height)
    this.columnData.maxFixTop = 0

    // uncomment this to have it stick to the bottom too rather than just
    // the top
    // columnData.maxFixTop = Math.max(
    //   0, this.winHeight - columnData.height)

    if (this.balance_enabled && this.columnData.enabled) {
      // other css for this element is handled in balance()
      this.inner.style.width = this.columnStyles.width
    } else {
      // reset state
      this.inner.style.width = null
    }
    this.balance(true)
  }
  resize (winWidth, winHeight) {
    this.winHeight = winHeight

    if (this.settings.minwidth !== null) {
      this.balance_enabled = (winWidth >= this.settings.minwidth)
    }
    this.initialize()
  }
  scroll (scrollTop, scrollLeft) {
    this.scrollDelta = scrollTop - this.scrollTop
    this.scrollTop = scrollTop
    this.scrollLeft = scrollLeft
    this.balance(false)
  }
  bind () {
    let resizeTimer
    /* Bind scrollbalance handlers to the scroll and resize events */
    window.addEventListener('resize', (e) => {
      // debounced
      clearTimeout(resizeTimer)
      resizeTimer = window.setTimeout(() => {
        const wSize = windowSize()
        this.resize(wSize.width, wSize.height)
      }, 250)
    })
    window.addEventListener('scroll', (e) => {
      const wScroll = windowScroll()
      this.scroll(wScroll.top, wScroll.left)
    })
    // init call
    const wSize = windowSize()
    const wScroll = windowScroll()
    this.resize(wSize.width, wSize.height)
    this.scroll(wScroll.top, wScroll.left)
  }
  unbind () {
    /* Unbind all scrollbalance handlers. */
    window.removeEventListener('resize', this.resize)
    window.removeEventListener('scroll', this.scroll)
  }
  disable () {
    /* Temporarily disable scrollbalance */
    this.balance_enabled = false
    this.initialize()
  }
  enable () {
    /* Re-enable scrollbalance */
    this.balance_enabled = true
    this.initialize()
  }
  teardown () {
    /* Remove all traces of scrollbalance from the content */
    if (this.inner.setAttribute('data-sb-created')) {
      // TODO check this moves content
      const children = [...(this.inner.children)]
      children.forEach((child) => { this.column.parentElement.appendChild(child) })
    }
    this.column.parentElement.style = null
    this.inner.style.position = null
    this.inner.style.top = null
    this.inner.style.left = null
    this.inner.style.width = null
    this.columnStyles = null

    this.unbind()
  }
  balance (force) {
    /* Using the scroll position, container offset, and column
       height, determine whether the column should be fixed or
       absolute, and position it accordingly. */

    let state
    let fixTop = this.columnData.fixTop

    if (this.scrollDelta === undefined) {
      this.scrollDelta = 0
    }

    if (!this.columnData.enabled || !this.balance_enabled) {
      state = 'disabled'
    } else {
      // determine state, one of
      // - top
      // - bottom
      // - fixed

      const topBreakpoint = this.containerTop - this.columnData.fixTop

      const bottomBreakpoint = this.containerTop + this.containerHeight -
        this.columnData.height - this.columnData.fixTop

      if (this.scrollTop < topBreakpoint) {
        state = 'top'
      } else if (this.scrollTop > bottomBreakpoint) {
        state = 'bottom'
      } else {
        state = 'fixed'
        fixTop = this.columnData.fixTop - this.scrollDelta
        fixTop = Math.max(this.columnData.minFixTop,
          Math.min(this.columnData.maxFixTop, fixTop))
      }
    }

    // update column positioning only if changed
    if (this.columnData.state !== state || this.columnData.fixTop !== fixTop || force) {
      if (state === 'disabled') {
        this.inner.style.position = null
        this.inner.style.top = null
        this.inner.style.left = null
      } else if (state === 'fixed') {
        this.inner.style.position = 'fixed'
        this.inner.style.top = `${fixTop}px`
        this.inner.style.left = `${this.columnData.fixLeft - this.scrollLeft}px`
      } else {
        // assume one of "bottom" or "top"
        this.inner.style.position = 'absolute'
        this.inner.style.top = `${(state === 'bottom' ? this.containerHeight -
                              this.columnData.height : 0)}px`
        this.inner.style.left = null
      }
      this.columnData.fixTop = fixTop
      this.columnData.state = state
    }
  }
}
