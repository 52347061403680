import register from '../../util/init'
import {getOffset} from '../../util/tools'
import {addHandler} from '../../util/scroll_resize'
import {extractHash} from '../../util/ajax_util'
import {PHONE_MAX} from '../constants'

import {headerHeight, collapseGallery, galleryExpanded} from '../site'

class initPage {
  constructor (container) {
    // establish a new teardown for ajax page unload
    this.teardown = []
    this.container = container
    this.windowWidth = null
    this.windowHeight = null
    this.rooms = container.querySelectorAll('.room-view .rooms .room')
    this.roomSelectors = this.container.querySelectorAll('.room-selector a')
    this.roomData = []

    // bind the room
    this.trackCurrentRoom()
    this.bindRoomSelectors()

    return {
      resize: (w, h) => {
        this.windowWidth = w
        this.windowHeight = h
      },
      scroll: (top, left) => {}
    }
  }
  /**
  * wider scope for reloading heights of rooms, callable from everywhere
  * mostly handled in trackCurrentRoom()
  *
  * @param {NodeList} rooms ('.room-view .room')
  */
  setRoomData (rooms) {
    rooms.forEach((room, index) => {
      const offset = getOffset(room)
      this.roomData[index] = {
        element: room,
        triggers: document.querySelectorAll(`li > a[href="#${room.id}"]`),
        top: offset.top,
        bottom: offset.top + room.offsetHeight,
        pseudo: false
      }
    })
  }
  /**
  * when scrolling, track which of the rooms are 'current' (if any)
  *
  */
  trackCurrentRoom () {
    let previousRoom = null
    // let debounceTimer
    const trackCurrentRoom = (triggerPoint) => {
      let current
      // find the current obj
      for (let room in this.roomData) {
        if ((triggerPoint > this.roomData[room].top &&
            triggerPoint < this.roomData[room].bottom)) {
          // 1. actual current
          this.roomData[room].pseudo = false
          current = this.roomData[room]
          break
        } else if ((triggerPoint < this.roomData[room].top && room === '0') ||
        (triggerPoint > this.roomData[room].bottom && room === `${this.roomData.length - 1}`)) {
          // 2. pseudo current (before / after)
          this.roomData[room].pseudo = true
          current = this.roomData[room]
          break
        }
      }
      return current
    }
    const setCurrentClasses = (currentRoom) => {
      let currentUrl = currentRoom ? `#${currentRoom.element.id}` : null
      // 1. rooms
      this.rooms.forEach((r) => {
        // strip previous
        if (r.classList.contains('state-current-room') &&
            ((currentRoom && r !== currentRoom.element) || !currentRoom)) {
          r.classList.remove('state-current-room')
        }
      })
      if (currentRoom) {
        currentRoom.element.classList.add('state-current-room')
      }
      // 2. triggers
      this.roomSelectors.forEach((t) => {
        // strip previous
        if (t.classList.contains('current')) {
          t.classList.remove('current')
        }
        // set the current
        if (t.getAttribute('href') === currentUrl) {
          t.classList.add('current')
        }
      })
      // 3. pseudo states
      if (currentRoom && currentRoom.pseudo) {
        document.body.classList.add('state-pseudo-room')
      } else {
        document.body.classList.remove('state-pseudo-room')
      }
    }

    const handler = addHandler({
      resize: (w, h) => {
        // reset
        this.setRoomData(this.rooms)
      },
      scroll: (y, x) => {
        // const triggerPoint = y + (headerHeight + 5) // just under header
        const triggerPoint = y + (this.windowHeight * 0.75)  // 1/4 of the the screen

        // track our current room: returns an obj (see setRoomData())
        const currentRoom = trackCurrentRoom(triggerPoint)
        if (!galleryExpanded) {
          // actions
          // clearTimeout(debounceTimer)
          // debounceTimer = window.setTimeout(() => {
          setCurrentClasses(currentRoom)
          // }, 100)
          // TODO current url?

          // preserve the previous 'room'
          previousRoom = (currentRoom && (currentRoom !== previousRoom)) ? currentRoom : null
        } else if (currentRoom.pseudo) {
          collapseGallery(currentRoom.element)
        }
      }
    }, true)

    this.teardown.push(handler)
  }
  /**
  * handle transitions from room to room when clicking through
  *
  */
  bindRoomSelectors () {
    this.roomSelectors.forEach((trigger) => {
      const hash = extractHash(trigger.getAttribute('href'))
      const target = document.getElementById(`${hash}`)
      trigger.addEventListener('click', (e) => {
        e.preventDefault()
        const scrollPosition = getOffset(target).top - (headerHeight - 2) // includes lines
        document.body.classList.add('pause-current-room')
        target.classList.add('force-current-room')
        // warning dirty timeouts
        window.setTimeout(() => {
          // document.documentElement.scrollTop = scrollPosition
          // document.body.scrollTop = scrollPosition
          window.scroll({
            top: scrollPosition,
            left: 0,
            behavior: 'smooth'
          })
          window.setTimeout(() => {
            // reset
            document.body.classList.remove('pause-current-room')
            target.classList.remove('force-current-room')
          }, 1800)
        }, 500)
      })
    })
  }
}
register('.accommodation-content', initPage)
