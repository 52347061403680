import {windowScroll} from '../util/scroll_resize'

//
// function after (n, t) {
//   return function () {
//     return --n < 1 ? t.apply(this, arguments) : void 0
//   }
// }

function getmatch (str, re, i) {
  /* Find and return the ith matched pattern in a regex, or
     return a blank string if not found */

  var match = str.match(re)
  if (match) {
    return match[i]
  } else {
    return ''
  }
}

export function getCurrentUrl () {
  /* Get fully qualified current url. */

  return window.location.pathname + window.location.search +
         window.location.hash
}

export function isLocalLink (link) {
  /* Return true if the link points to an html page within the site. */

  var domain = getmatch(window.location.href, /[^/]+\/\/[^/]+/, 0)

  return link.matches('a[href]:not([href^="http://"]):not([href^="https://"]), ' +
                      'a[href^="' + domain + '"]') &&
         !link.matches('[href$=".xml"], [href$=".pdf"], [href$=".jpg"], ' +
                       '[href$=".gif"], [href$=".png"], [href^="#"], ' +
                       '[href^="mailto:"]')
}

export function extractHash (url) {
  // strip out url + ?...
  let hash
  const start = url.indexOf('#') > 0 ? url.indexOf('#') + 1 : 1
  const end = url.indexOf('?') > 0 ? url.indexOf('?') : url.length
  hash = url.substring(start, end)
  return hash
}

// function isNumber (value) {
//   return typeof value === 'number' && isFinite(value)
// }

export const ajaxUrl = (url) => {
  return url + (url.match(/\?/) ? '&' : '?') + 'ajax=1'
}

export default function AjaxLoader (ns, loader, options) {
  /* Handle ajax loading and window history state within a given namespace.
     Requires jquery and Modernizr.history

  - ns: unique namespace for this loader
  - loader: function (newBody)
    function which inserts the new body content into the page, or whatever
    subset is required

  options:
  - ajaxUrl: function (url)
    converts a normal url to an ajax one. Will use standard url if not set
  - onBeforeLoad: function (url)
    called before a load is executed, return false to cancel
  - onStateChange: function ()
    called when history state changes

  methods:
  - load: function (url, scrollTarget, state)
    load a given url, scrollTarget is an optional pixel position to scroll,
    state is used internally by popstate

  */
  this.loader = loader
  this.ns = ns

  this.options = Object.assign({
    ajaxUrl: ajaxUrl,
    history: window.Modernizr.history
  }, options || {})

  this.bind()
}

AjaxLoader.prototype = {
  load: function (url, state) {
    /* scrollTarget defaults to 0 if the url doesn't contain a # fragment. */
    var html
    var that = this

    function pushState () {
      if (that.options.history && !state) {
        // replace original state so original loaded page works
        window.history.replaceState({
          ns: that.ns,
          path: url,
          scrollTop: windowScroll().top
        }, null, url)
        // only push state if it's a new page load
        window.history.pushState({
          ns: that.ns
        }, null, url)
      }
      // if (that.options.onStateChange) {
      //   that.options.onStateChange(url)
      // }
    }

    pushState()

    if (this.options.onBeforeLoad) {
      this.options.onBeforeLoad(url)
    }

    var reveal = function () {
      // populate title
      var title = html.title
      if (title) {
        document.title = title
      }
      // run custom loader
      that.loader(html.body, state)
    }

    var ajaxUrl = this.options.ajaxUrl ? this.options.ajaxUrl(url) : url

    function doLoad (h) {
      html = new window.DOMParser().parseFromString(h, 'text/html')
      reveal()
    }

    const params = {
      method: 'get',
      headers: {
        'X-Requested-With': 'XMLHttpRequest'
      }
    }

    // if we're using nginx basic auth throw that on with our requests (for safari)
    if (window.BASIC_AUTH) {
      params.headers.Authorization = `Basic ${window.btoa(window.BASIC_AUTH)}`
    }

    return window.fetch(ajaxUrl, params).then((response) => {
      return response.text()
    }).then(doLoad).catch((err) => {
      // DEBUG...
      alert(err)
      window.location = url
      // if (xhr.status === 404) {
      //   doLoad(xhr.responseText)
      // } else {
      //   window.location = url
      // }
    })
  },
  bind: function () {
    var that = this
    if (this.options.history) {
      window.addEventListener('popstate', e => {
        if (e.state && e.state.ns === that.ns) {
          window.location.reload()
          return false
        }
      })
      // window.addEventListener('popstate', e => {
      //   // check state to avoid handling Crapfari's pageload popstate event
      //   if (e.state && e.state.ns === that.ns) {
      //     var url = getCurrentUrl()
      //     that.load(url, e.state)
      //   }
      // })
    }
  }
}
